import { navigate, graphql, useStaticQuery } from "gatsby"
import React, { useState, useEffect } from "react"

export const Context = React.createContext({})

const query = graphql`
  query {
    allStrapiArea {
      nodes {
        id
        area_bg
        area_en
        post_code
        coefficient
        pop
      }
    }
  }
`

const SiteProvider = props => {
  const areaData = useStaticQuery(query)
  const areas = areaData.allStrapiArea.nodes

  const [findPost, setFindpost] = useState(null)
  const [formIdeaGallery, setFromIdeaGallery] = useState(false)
  const [commingSoon, setCommingSoon] = useState(false)
  const [currentFilter, setCurrentFilter] = useState("all")
  const [inititativeData, setInititativeData] = useState(null)
  const [loading, setLoading] = useState(false)

  const findPostHandler = id => {
    setFindpost(id)
    navigate("/useful")
  }

  function sortByVotes(a, b) {
    return Number(a.votes) - Number(b.votes)
  }

  const getInitiatives = async () => {
    setLoading(true)
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    try {
      const response = await fetch(
        `https://halobg.herokuapp.com/api/initiatives`,
        requestOptions
      )
      const data = await response.json()

      const areasInfo = areas
        .map(area => {
          const info = {
            area_bg: area.area_bg,
            area_en: area.area_en,
            population: area.pop,
            coefficient: area.coefficient,
            post_code: area.post_code,
            votes: 0,
          }
          data.data.forEach(el => {
            const initiativeArea = el.attributes.area_en

            if (initiativeArea === area.area_en) {
              info.votes = info.votes + Number(el.attributes.votes)
            }
          })

          return info
        })
        .map(area => {
          return {
            ...area,
            votes: Number(
              (
                ((area.votes * Number(area.coefficient)) /
                  Number(area.population)) *
                100
              ).toFixed(3)
            ),
          }
        })

      // const sortedItems = areasInfo.sort(sortByVotes).slice(-5).reverse()
      const sortedItems = areasInfo.sort(sortByVotes).reverse()

      setInititativeData(sortedItems)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!inititativeData) {
      getInitiatives()
    }
  }, [])

  return (
    <Context.Provider
      value={{
        findPostHandler,
        setFindpost,
        findPost: findPost,
        setFromIdeaGallery,
        formIdeaGallery,
        setCommingSoon,
        commingSoon,
        setCurrentFilter,
        currentFilter,
        loading,
        inititativeData,
        areas,
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export default SiteProvider
