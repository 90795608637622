exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-platform-js": () => import("./../../../src/pages/about/platform.js" /* webpackChunkName: "component---src-pages-about-platform-js" */),
  "component---src-pages-about-programme-js": () => import("./../../../src/pages/about/programme.js" /* webpackChunkName: "component---src-pages-about-programme-js" */),
  "component---src-pages-about-project-js": () => import("./../../../src/pages/about/project.js" /* webpackChunkName: "component---src-pages-about-project-js" */),
  "component---src-pages-about-team-js": () => import("./../../../src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-idea-js": () => import("./../../../src/pages/idea.js" /* webpackChunkName: "component---src-pages-idea-js" */),
  "component---src-pages-idea-list-js": () => import("./../../../src/pages/idea-list.js" /* webpackChunkName: "component---src-pages-idea-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initiatives-js": () => import("./../../../src/pages/initiatives.js" /* webpackChunkName: "component---src-pages-initiatives-js" */),
  "component---src-pages-ranking-js": () => import("./../../../src/pages/ranking.js" /* webpackChunkName: "component---src-pages-ranking-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-useful-js": () => import("./../../../src/pages/useful.js" /* webpackChunkName: "component---src-pages-useful-js" */)
}

